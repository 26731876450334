/********************************************
* HOME PAGE UNCOMPRESSED STYLE
********************************************/
@import "../base/variables";
@import "../base/mixins";

.home{
  @include media('small'){
    overflow-x: hidden;
  }
  /* Start mosaico instagram */
  .mosaico-bg{
    background-color: $second-color;
  }
  .mosaico{ 
    background-color: $second-color;
    padding-top: 20px;
    padding-bottom: 20px;
    .box{
      display: flex;
    }
    .top{
      margin-bottom: 20px;
      .instagram{
        text-align: center;
      }
      img{
        padding-right: 20px;
        float: left;
      }
      h2{
        margin: 0;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
      }
      .insta{
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: $primary-color;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 30px;
      }
      .siga{
        font-size: 32px;
        color: $primary-color;
        display: flex;
        margin-top: 18px;
        font-weight: 600;
        margin-left: -75px;
        @include media('small') {
          display: table;
          margin-left: auto;
          margin-right: auto;
          margin-top: -26px;
        }
      }
    }
    .col{
      width: 100%;
      @include media("mf-x-small"){
        width: 50%;
      }
      .item{
        overflow: hidden;
        img, video{
          max-width: 100%;
          height: auto !important;
        }
      }
      &.col-1,
      &.col-2{
        .item{
          &:nth-child(1){
            float: left;
            margin-right: 4px;
            @include media("small"){
              width: 68%;
              height: 196px;
              text-align: -webkit-right;
            }
            img, video{
              width: 100%;
              height: 200px !important;
              object-fit: cover;
              @include media("large"){
                width: 312px;
                height: 304px !important;                
              }
              @include media ('lg'){
                width: 372px;
                height: 385px !important;
              }
            }
          }
          &:nth-child(2),
          &:nth-child(3){
            float: left;
            padding-bottom: 1%;
            @include media("small"){
              width: 30%;
            }            
            img, video{
              width: 100%;
              height: 96.5px !important;
              object-fit: cover;
              @include media ('large'){
                width: 150px;
                height: 150px !important;
              }  
              @include media ('lg'){
                width: 190px;
                height: 190px !important;
              }              
            }             
          }                    
        }
      }
      &.col-2{
        display: none;
        @include media("custom-small"){
          display: block;
        }
      }
    }
  }
  /* End mosaico Instagram */

  /* Start banners */
  .banner-top{
    .control{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 2;

      &.prev{
        left: 20px;
        width: 53px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../images/arrow-prev-white.svg');
        .fa{
          display: none;
        }
      }
      &.next{
        right: 20px;
        width: 53px;
        border-radius: 5px 0 0 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../images/arrow-next-white.svg');
        .fa{
          display: none;
        }
      }
    }    
  }
  .banner-principal,
  .banner-principal2{
    @include media('mf-large'){
      .owl-carousel .owl-nav{
        display: unset !important;
        z-index: 100000000;
        position: absolute;
        bottom: 50%;
        width: 100%;
        .owl-next{
          border-radius: 5px 0 0 5px;
          background-position: center right;
          background-repeat: no-repeat;
          background-image: url(../../images/arrow-next-white.svg);
          font-size: 34px;
          color: transparent;
          float: right;
          margin-right: 20px;
        }
        .owl-prev{
          border-radius: 5px 0 0 5px;
          background-position: center left;
          background-repeat: no-repeat;
          background-image: url(../../images/arrow-prev-white.svg);
          font-size: 34px;
          color: transparent;
          display: -webkit-inline-box;
          margin-left: 20px;
        }
      }        
    }  
  }
  .banner-principal{
    position: relative;

    ul{
      list-style: none;
    }
    .owl-dots{
      display: inline-flex;
      // display: none;
      position: absolute;
      left: 50%;
      bottom: 0%;
      @include transform(translateY(-50%));

      .owl-dot{
        &:not(:last-child){
          margin-bottom: 4px;
        }
        &.active{
          span{
            background: $primary-color;
          }
        }
        span{
          display: block;
          margin-left: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid $primary-color;

          @include media ('xs'){
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
  .banner-menor-mob{
    margin-top: 4%;
    @include media ('large'){
      margin-top: 13%;
    }
  }
  .categorias{
    padding: 25px 0;
    background-color: #f4f4f4;

    .list{
      margin: 0 -5px;
      width: auto;
      display: flex;
      flex-wrap: wrap;

      .column{
        padding: 5px;
        width: 20%;

        &.first{
          .block{
            margin-top: 30px;
          }
        }

        &.first{
          .inner{
            padding: 0;
          }
        }
        .inner{
          height: 150px;
          border-radius: 5px;
          color: $second-color;
          padding: 10px;
          text-align: center;
          display: flex;
          align-items: center;
          flex-flow: column;
          &:hover{
            .info-img{
              top: -5px;
            }
          }
          &.title{
            text-align: left;

            strong{
              display: block;
              font-size: 26px;
              font-weight: bold;
              color: $second-color;
              line-height: 1em;
              text-transform: uppercase;
            }
            .top{
              span{
                font-family: Roboto;
                font-size: 22px;
                font-weight: 100;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2c3691;
              }
              img{
                margin-left: 20px;
              }
            }
          }
          img{
            margin-bottom: 7px;
            transition: 200ms ease;
            position: relative;
            top: 0;
          }
          h3{
            text-transform: uppercase;
            font-size: 20px;
            font-weight: lighter;
          }
          span{
            font-weight: lighter;
            font-size: 12px;

            .fa{
              color: #fdd31e;
              margin-left: 2px;
            }
          }
          .main-link{
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
  .banner-menor-mob-container{
    .owl-dots{
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 15px;

      .owl-dot{
        height: 15px;
        width: 15px;
        display: inline-block;
        border: 1px solid $primary-color;
        border-radius: 10px;
        margin-right: 5px;

        &.active{
          background: $primary-color;
        }
      }
    }
  }
  .banner-menor-container{
    padding: 10px 0;
    img{
      width: 100%;
    }
    .condicao{
      padding: 0 5px;
    }
  }
  .banner-condicoes{
    padding: 0;
    margin-top: 10px;
    & > .box-content{
      @include media("xxs"){
        padding: 5px;
      }
    }
    .banner-condicoes-span{
      display: inline-flex;
      align-items: center;

      img{
        margin-right: 10px;
      }
    }

    @include media ('small'){
      height: 155px !important;

      .div-condicoes{
        height: 26px;

        &:nth-child(1) {
          height: 39px;
        }        
      }      
    } 
  }
  .banner-condicoes-mob{
    padding: 0;
    margin-top: 10px;
    .condicao{
      padding: 0 10px;
      margin-bottom: 10px;
      img{
        width: 100%;
      }
    }
  }
  .banner-middle{
    position: relative;

    .control{
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 2;
      @include media("mf-x-small"){
        display: flex;
      }
      &.prev{
        left: 20px;
        width: 53px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../images/arrow-prev-white.svg');
        .fa{
          display: none;
        }
      }
      &.next{
        right: 20px;
        width: 53px;
        border-radius: 5px 0 0 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../images/arrow-next-white.svg');
        .fa{
          display: none;
        }
      }
    }
  }
  .banner-middle-mob {
    padding: 0;
    margin-top: 10px;
    .condicao {
      padding: 0 10px;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
  }
  .banner-home-container{
    margin: 20px 0;
    @include media("xs"){
      margin: 10px 0;
    }
  }
  .banner-home{
    @include media('xs'){
      padding: 0 10px;
    }
    .secundario{
      width: 33% !important;
      padding: 0 6px !important;
      @include media('xs'){
        width: 100% !important;
        padding: 0 !important;
        margin-bottom: 10px;
        // &.secundario2,
        // &.secundario3,
        // &.secundario5,
        // &.secundario6{
        //   display: none;
        // }
      }
    }
  }
  /* End banners */

  @include media('small'){
    .frete-gratis{
      img{
        margin-right: 0 !important;
        vertical-align: sub;
        float: left;
        margin-top: 5px;
        padding-left: 10%;
      }
      span:last-child{
        display: block;
      }
    }
    .banner-principal .owl-dots{
      left: 0;
      top: 95%;
      display: block;
      width: 100%;
      text-align: center;
      .owl-dot{
        display: inline-flex;
        margin-right: 8px;
      }
    }
    .categorias{
      .list{
        margin: 0 -10px;
        .column{
          width: 50%;
          padding: 2px;
          &.first{
            .inner{
              .block{
                margin-top: 30px;
                img{
                  display: block;
                  margin-bottom: 0;
                  margin-left: 15px !important;
                  width: 40px !important;
                  height: 40px !important;
                }
              }
            }
          }
          .inner{
            height: 150px;
            .main-link{
              font-size: 15px;
            }
            &.title{
              strong{
                font-size: 22px;
                line-height: 1;
              }
            }
            img{
              margin-bottom: -5px;
            }
            h3{
              margin-bottom: 5px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

 @include media('md') {
   .categorias .list .column .inner.title strong {
     font-size: 27px;
   }
   .mosaico {
     margin-bottom: 50px;
   }
 }

 @include media('small') {
   .mosaico {
     flex-direction: column;

     .top {
       flex-direction: row;
       padding: 18px;
       justify-content: center;

       .instagram {
         display: flex;
         flex-direction: column;
         align-items: center;

         .img-insta {
           width: 45px;
           padding-right: unset;
         }
         .insta {
           margin-top: -14px;
         }
       }
       .img-upman {
          width: 110px;
          display: block;
          margin: 0 auto;
       }
     }
   }
  }
}

@include media('xmd') {
  .home .mosaico .box .col {
    width: 100%;
    margin-left: 3px;
  }
}

/* Banners N49 */ 
.slick-dots{
	list-style: none;
  display: inline-flex;
  position: absolute;
  left: 50%;
  bottom: 0%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-bottom: 20px;
	li{
		button{
			display: block;
			margin-left: 10px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid #fff;
			background-color: transparent;
			color: transparent;			

		}
	}
	.slick-active{
		button{
			background-color: white;
			color: white;					
		}
	}

}

.slick-arrow{
  display: unset !important;
  z-index: 1;
  position: absolute;
  bottom: 50%;
  width: 100%;
  border: 0;
  background-color: transparent;
  &.slick-prev{
    border-radius: 5px 0 0 5px;
    background-position: center left;
    background-repeat: no-repeat;
    background-image: url(../../images/arrow-prev-white.svg);
    font-size: 34px;
    color: transparent;
    display: -webkit-inline-box;
    margin-left: 20px;
  }  
  &.slick-next{
    border-radius: 5px 0 0 5px;
    background-position: center right;
    background-repeat: no-repeat;
    background-image: url(../../images/arrow-next-white.svg);
    font-size: 34px;
    color: transparent;
    float: right;
    margin-left: -20px;
  }    
}

.banner-single{
  &.secundario{
    .slick-arrow{
      display: none !important;
    }
    .slick-dots{
      display: none !important;
    }
  }
  @include media('xs'){
    .slick-arrow{
      display: none !important;
    }
    .slick-dots{
      display: none !important;
    }    
  }
	.item{
		a{
			img{
				width: 100%;
			}
		}
	}
}
/* End Banners N49 */
